import React from 'react';
import { Routes, Route } from "react-router-dom";
import { DataProvider } from './components/DataProvider';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Update from './components/Update';
import AuthConfigForm from './Config/AuthConfigForm';


function App() {

  return (
    <DataProvider>
      <Routes>
        <Route path={process.env.REACT_APP_PUBLIC_URL} element={<SignIn />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}sign-in`} element={<SignIn />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}sign-up`} element={<SignUp />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}update`} element={<Update />} />

        <Route path={`${process.env.REACT_APP_PUBLIC_URL}config`} element={<AuthConfigForm />} />
      </Routes>
    </DataProvider>
  );
}

export default App;
