import axios from "axios";

console.log({url: process.env.REACT_APP_BACKEND})

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  timeout: 30 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});
