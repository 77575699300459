import React, { Fragment, useState } from 'react'
import { LoginUser, TwoFaCodeVerifySignInToken } from '../../api/api';
import InputSign from '../../AuthV1/components/inputSign/InputSign';
import { useDataProvider } from '../../components/DataProvider';
import { useTranslation } from 'react-i18next';
import { Device } from '../AuthTwoFaVerify';

type Props = {
    device: Device,
    authOptions: LoginUser,

    onSuccess: (token: string) => void,
}

export default function VerifyCodeComponent(props: Props) {
    const { t } = useTranslation('');

    const { AccentColor } = useDataProvider();

    const [token, setToken] = useState('');
    const [isError, setIsError] = useState(false);

    const onSubmit = async () => {
        const res = await TwoFaCodeVerifySignInToken(
            {
                secret: props.device.secret,
                token,
                authOptions: props.authOptions,
            }
        )
        if (res.data.action === 'logged-in') {
            props.onSuccess(res.data.token);
        } else {
            setIsError(true);
        }
    }

    return (
        <Fragment>
            <ul className='list-disc'>
                <li>Launch Authy or Google Authenticator on your mobile device.</li>
                <li>Locate the six-digit verification code for the corresponding account.</li>
                <li>Enter the generated code from Authy or Google Authenticator into the verification field.</li>
                <li>Submit the code, and upon successful verification, gain access to your account.</li>
            </ul>

            <InputSign onEnter={onSubmit} title={t('Verify code')} value={token} handleChange={setToken} isWrongCredentials={false} />

            {isError && <p className={'text-[#EF4444] text-[12px]'}>{t('Wrong token')}</p>}

            <button
                onClick={onSubmit}
                className={'w-[100%] py-[16px] text-[#ffffff]'}
                style={{
                    backgroundColor: AccentColor,
                }}
            >
                {t('Prisijungti')}
            </button>
        </Fragment>
    )
}
