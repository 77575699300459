import { FC } from 'react'
import { useDataProvider } from '../../components/DataProvider'

type Props = {
  children: JSX.Element
}
export const Wrapper: FC<Props> = ({ children }) => {
  const { ImageTop } = useDataProvider();

  return (
    <div className='m-0 lg:m-10 w-full lg:w-[600px] rounded py-11 shadow-none lg:shadow-lg px-4 lg:px-16 lg:bg-white'>
      <div>
        {ImageTop.map(url => <img src={url} className='mx-auto' alt="" />)}
      </div>
      {children}
    </div>
  )
}