import { ChangeEvent, FC, MouseEvent } from "react"
import { Input } from "./Input"
import successful from '../assets/successful.svg'
import close from '../assets/close.svg'
import { useTranslation } from "react-i18next";
import { remindUser } from "../../api/api";

type Props = {
    isActive: boolean
    isSended: boolean
    value: string
    onChangeActive: (e: MouseEvent) => void
    onChangeSended: () => void
    onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Modal: FC<Props> = ({isActive, isSended, onChangeSended, onChangeActive, onChangeValue, value}) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    onChangeSended();
    remindUser({
        username: value
    })
}
  
  return (
    <>
      {
        <div onClick={onChangeActive} className={ isActive ? "w-full  bg-black/40 h-full fixed top-0" : 'hidden'}>
          <div onClick={(e) => e.stopPropagation()} className="shadow-md p-10 bg-white rounded-lg max-w-[535px] mx-auto relative top-1/3">
            {!isSended 
              ? <>
                <p className="text-3xl  font-medium">{t('Atstatyti slaptažodį')}</p>
                <p className="mt-6">{t('Įveskite savo el. pašto adresą ir mes atsiųsime jums el. laišką su instrukcijom.')}</p>
                <p className="mt-6 mb-2 font-semibold">{t('El. paštas')}</p>
                <Input value={value} onChangeValue={onChangeValue} placeholder={t('El. paštas')} type="email" isValid={true}/>
                <button onClick={onSubmit} className={` mt-2 duration-200 focus:outline-none  rounded-md text-white py-2 px-4 ${value.length > 5 ? 'hover:bg-teal-700 bg-teal-600' : 'bg-teal-600/50'}`}>{t('Atstatyti')}</button>
              </> 
              : 
              <div className="flex items-start">
                <img className="" src={successful} alt="successful" />
                <div className="ml-4">
                  <p className="text-3xl leading-7 mb-4 font-medium">{t('Patikrink savo paštą')}</p>
                  <p>{t('Išsiuntėme el. laišką adresu su instrukcijomis.', {email: value})}</p>
                </div>
              </div>
            }
            <div onClick={onChangeActive}  className={!isSended ? "absolute top-12 cursor-pointer right-5" : 'hidden'}>
              <img   src={close} alt="" />
            </div>
          </div>
        </div>
      }
    </>
  )
}