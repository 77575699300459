import i18next from 'i18next';
import React, { ReactNode } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { axiosInstance } from '../api/config';

export type DataProviderProps = {
    children?: ReactNode;
};

export interface DataProviderValue {
    AllowRegistration: boolean,
    AllowPasswordRemind: boolean,
    AllowRemindMe: boolean,
    SignUpShowFirstName: boolean,
    SignUpShowLastName: boolean,
    SignUpShowUsername: boolean,
    PrivacyPolicyUrl: string | null,
    Lang: string,
    Design: string,
    ImageLeft: string,
    ImageTop: string[],
    ImageBottom: string[],
    BackgroundColor: string,
    AccentColor: string,
}

const dfValue = {
    AllowRegistration: false,
    AllowPasswordRemind: false,
    AllowRemindMe: false,
    SignUpShowFirstName: false,
    SignUpShowLastName: false,
    SignUpShowUsername: false,
    PrivacyPolicyUrl: null,
    Lang: "en",
    Design: "v1",
    ImageLeft: "",
    ImageTop: [],
    ImageBottom: [],
    BackgroundColor: "#FFF",
    AccentColor: "#0EA5E9",
};

export const DataProviderContext = React.createContext<DataProviderValue>(dfValue);

export const useDataProvider = () => useContext(DataProviderContext);

export function DataProvider(props: DataProviderProps) {
    const [settings, setSettings] = useState(dfValue);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axiosInstance.get('init').then((res) => {
            setSettings(res.data);
            setLoaded(true);
            i18next.changeLanguage(res.data.Lang, () => { })

            document.title = res.data.PageTitle;
        })
    }, []);

    return (
        <DataProviderContext.Provider value={{
            AllowRegistration: settings.AllowRegistration,
            AllowPasswordRemind: settings.AllowPasswordRemind,
            AllowRemindMe: settings.AllowRemindMe,
            SignUpShowFirstName: settings.SignUpShowFirstName,
            SignUpShowLastName: settings.SignUpShowLastName,
            SignUpShowUsername: settings.SignUpShowUsername,
            PrivacyPolicyUrl: settings.PrivacyPolicyUrl,
            Lang: settings.Lang,
            Design: settings.Design,
            ImageLeft: settings.ImageLeft,
            ImageTop: settings.ImageTop,
            ImageBottom: settings.ImageBottom,
            BackgroundColor: settings.BackgroundColor ? settings.BackgroundColor : '#FFF',
            AccentColor: settings.AccentColor ? settings.AccentColor : "#0EA5E9",
        }}>
            {loaded && props.children}
        </DataProviderContext.Provider>
    );
}
