import React from 'react'
import { useDataProvider } from './DataProvider'
import SignInV1 from './../AuthV1/screens/SignIn/SignIn';
import { SignIn as SignInV2 } from './../AuthV2/pages/SignIn';

export default function SignIn() {
    const { Design } = useDataProvider();

    if (Design === 'v1') {
        return <SignInV1 />
    }

    return <SignInV2 />
}
