import React, { useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import InputSign from "../../components/inputSign/InputSign";
import ModalForgotPassword from "../../components/modalForgotPassword/ModalForgotPassword";
import { useTranslation } from "react-i18next";
import TopLogo from '../../components/UI/TopLogo';
import Wrapper from '../../components/UI/Wrapper';
import { doLogin, onLoginSuccess } from '../../../api/front-api';
import { useDataProvider } from '../../../components/DataProvider';
import Auth2faRegister from '../../../AuthTwoFa/AuthTwoFaRegister';
import Auth2faVerify from '../../../AuthTwoFa/AuthTwoFaVerify';
import { remindUser } from '../../../api/api';

function SignIn() {
    let [searchParams] = useSearchParams();

    const [auth2FaVerifyData, setAuth2FaVerifyData] = useState<any>(undefined);
    const [auth2FaRegisterData, setAuth2FaRegisterData] = useState<any>(undefined);

    const { AllowRemindMe, AllowPasswordRemind, AllowRegistration, ImageBottom, AccentColor } = useDataProvider();

    const { t } = useTranslation();

    const [isWrongCredentials, setIsWrongCredentials] = useState(false);

    const [error, setError] = useState('');

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [userRemindMe, setUserRemindMe] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false)

    const openForgotPassword = () => {
        if (forgotPassword === false) {
            setForgotPassword(true)
        }
        if (forgotPassword === true) {
            setForgotPassword(false)
        }
    }

    const onSubmit = async () => {
        let c = searchParams.get('callback');
        if (!c) {
            c = searchParams.get('redirect');
        }

        const res = await doLogin(
            {
                username: login,
                password: password,
                remindMe: userRemindMe,
            },
            setError,
            !!c && typeof c === 'string' ? c : '/'
        );

        if (!!res && res.action === 'need-2fa-register') {
            setAuth2FaRegisterData(res);
        }
        if (!!res && res.action === 'need-2fa-verify') {
            setAuth2FaVerifyData(res);
        }
    }

    const doOnLoginSucces = (token: string) => {
        let c = searchParams.get('callback');
        if (!c) {
            c = searchParams.get('redirect');
        }
        onLoginSuccess(!!c && typeof c === 'string' ? c : '/', token);
    }

    return (
        <Wrapper>
            <div className={'w-full lg:w-[350px] xl:w-[450px] space-y-[24px]'}>
                <TopLogo />
                <div className={'text-[28px]'}>{t('Prisijunkite')}</div>

                <InputSign onEnter={onSubmit} title={t('Vartotojas')} value={login} handleChange={setLogin} isWrongCredentials={isWrongCredentials} />
                <InputSign onEnter={onSubmit} title={t('Slaptažodis')} type={"password"} value={password} handleChange={setPassword} isWrongCredentials={isWrongCredentials} />

                {(!!AllowPasswordRemind || !!AllowRemindMe) &&
                    <div className="flex items-center justify-between">
                        {!!AllowRemindMe &&
                            <div className="flex items-center" onClick={() => setUserRemindMe(!userRemindMe)}>
                                <input id="remindMe" checked={userRemindMe} type="checkbox" className={'w-[21px] h-[21px]'} />
                                <label htmlFor='remindMe' className={"pl-[8px] text-[14px] font-normal"}>{t('Prisiminti')}</label>
                            </div>
                        }
                        {!!AllowPasswordRemind &&
                            <div
                                className={'cursor-pointer'} onClick={() => openForgotPassword()}
                                style={{
                                    color: AccentColor,
                                }}
                            >
                                {t('Pamiršote slaptažodį?')}
                            </div>
                        }
                    </div>
                }

                {!!error && <div className='duration-200 bg-red-50 text-red-700 p-2 rounded-md'>{t(error)}</div>}

                <button
                    onClick={onSubmit}
                    className={'w-[100%] py-[16px] text-[#ffffff]'}
                    style={{
                        backgroundColor: AccentColor,
                    }}
                >
                    {t('Prisijungti')}
                </button>

                {!!AllowRegistration &&
                    <div
                        // className={'text-[#0EA5E9]'}
                        style={{
                            color: AccentColor,
                        }}
                    >
                        <Link to={`${process.env.REACT_APP_PUBLIC_URL}sign-up`} >{t('Sukurti paskyrą')}</Link>
                    </div>
                }

                <div
                    className={'border-t-[1px] opacity-30'}
                    style={{
                        borderColor: AccentColor
                    }}

                ></div>

                <div className="flex gap-2 items-center">
                    {ImageBottom.map((url, idx) => <img key={`imb-${idx}`} src={url} alt="" />)}
                </div>
            </div>
            {
                forgotPassword
                    ? <ModalForgotPassword setForgotPassword={setForgotPassword} />
                    : ''
            }

            {!!auth2FaRegisterData && <Auth2faRegister {...auth2FaRegisterData.data} authOptions={{username: login, password: password, remindMe: userRemindMe}} onSuccess={doOnLoginSucces} />}

            {!!auth2FaVerifyData && <Auth2faVerify {...auth2FaVerifyData.data} authOptions={{username: login, password: password, remindMe: userRemindMe}} onSuccess={doOnLoginSucces}/>}
        </Wrapper>
    );
}

export default SignIn;
