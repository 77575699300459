import React, { Fragment } from 'react'
import { Checkbox } from '../AuthV2/components'
import { useTranslation } from "react-i18next";
import { useDataProvider } from './DataProvider';

type Props = {
    isChecked: boolean
    onCheckedChange: (val: boolean) => void
}

export default function PrivacyPolicyCheckbox(props: Props) {
    const { PrivacyPolicyUrl } = useDataProvider();

    const { isChecked, onCheckedChange } = props;
    const { t } = useTranslation();

    return (
        <Fragment>
            <Checkbox name='privacy' className="mr-3" isChecked={isChecked} onCheckedChange={onCheckedChange} />
            <label htmlFor='privacy' className="text-sm">
                {t('Sutinku su sąlygomis')}
                <a className="text-sky-700 ml-2" href={PrivacyPolicyUrl ? PrivacyPolicyUrl : ""} target={"_blank"} rel="noreferrer">{t('Privatumo politika')}</a>
            </label>
        </Fragment>
    )
}
