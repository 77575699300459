import { createUser, CreateUserDto, LoginUser, loginUser } from "./api";
import validator from "validator";

export const doLogin = async (
  authOptions: LoginUser,
  setError: (val: string) => void,
  redirect: string = '/'
) => {
  setError("");

  if (!authOptions.username) {
    setError("Įveskite prisijungimo vardą");
    return "";
  }
  if (!authOptions.password) {
    setError("Įveskite slaptažodį");
    return "";
  }

  const res = await loginUser({
    password: authOptions.password,
    username: authOptions.username,
    remindMe: authOptions.remindMe
  });

  if (res.data.action === 'need-2fa-register' || res.data.action === 'need-2fa-verify') {
    return res.data;
  } else if (res.data.token) {
    onLoginSuccess(redirect, res.data.token);
  } else {
    setError("Neteisingas vartotojo vardas arba slaptažodis");
    return "";
  }
};

export const onLoginSuccess = (redirect: string, token: string) => {
  if (redirect.indexOf('http') >= 0) {
    if (redirect.indexOf('?') >= 0) {
      window.location.href = `${redirect}&token=${token}`;
    } else {
      window.location.href = `${redirect}?token=${token}`;
    }
  } else {
    window.localStorage.setItem("token", token);
    window.location.href = redirect;
  }
}

type DoSignUpProps = {
  setError: (val: string) => void;
  passwordRepeat: string;
} & CreateUserDto;

export const doSignUp = async (props: DoSignUpProps) => {
  const {
    email,
    firstName,
    lastName,
    phone,
    password,
    username,
    setError,
    passwordRepeat,
  } = props;

  setError("");

  if (!username) {
    setError("Įveskite prisijungimo vardą");
    return false;
  }

  if (!email) {
    setError("Įveskite el. pašto adresą");
    return false;
  }

  if (!validator.isEmail(email)) {
    setError("Neteisingas el. pašto adresas");
    return false;
  }

  if (!password) {
    setError("Įveskite slaptažodį");
    return false;
  }
  if (password.length < 8) {
    setError("Slaptažodį turi sudaryti bent 8 simboliai");
    return false;
  }
  if (password !== passwordRepeat) {
    setError("Slaptažodžiai nesutampa");
    return false;
  }

  try {
    const res = await createUser({
      email,
      firstName,
      lastName,
      phone,
      password,
      username,
    });

    if (res.data.id) {
    } else {
      if (res.data.error) {
        setError(res.data.error);
        return false;
      } else {
        setError("Klaida");
        return false;
      }
    }
  } catch (e) {
    setError("Klaida");
    return false;
  }
  return true;
};
