import { ChangeEvent, FC, MouseEvent, useState } from "react"
import { Button, Checkbox, Input, Modal, Wrapper } from "../components/index"
import { Link, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { doLogin } from "../../api/front-api";
import { useDataProvider } from "../../components/DataProvider";

type EventChange = ChangeEvent<HTMLInputElement>

export const SignIn: FC = () => {
  let [searchParams] = useSearchParams();

  const { AllowRemindMe, AllowPasswordRemind, AllowRegistration, ImageBottom } = useDataProvider();

  const { t } = useTranslation();

  const [error, setError] = useState('');

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const onChangeName = (e: EventChange) => setName(e.target.value)
  const onChangePassword = (e: EventChange) => setPassword(e.target.value)
  const onChangeChecked = () => setIsChecked(!isChecked)
  const onChangeVisible = () => setIsVisible(!isVisible)

  const [isActiveModal, setIsActiveModal] = useState(false)
  const [isSendedModal, setIsSendedModal] = useState(false)
  const [emailModal, setEmailModal] = useState('')

  const onChangeActiveModal = (e: MouseEvent) => {
    setIsActiveModal(!isActiveModal)
    setIsSendedModal(false)
    setEmailModal('')
  }
  const onChangeSendedModal = () => setIsSendedModal(!isSendedModal)
  const onChangeEmailModal = (e: EventChange) => setEmailModal(e.target.value)

  const onSubmit = () => {
    let c = searchParams.get('callback');
    if (!c) {
      c = searchParams.get('redirect');
    }
    doLogin({ username: name, password: password, remindMe: isChecked }, setError, !!c && typeof c === 'string' ? c : '/');
  }

  return (
    <div className="flex items-center justify-center h-screen lg:bg-slate-50">
      <Wrapper>
        <div className="mt-10 space-y-[24px]">
          <p className="text-3xl font-medium">{t('Prisijunkite')}</p>

          <div>
            <p className="text-left text-sm font-semibold mb-2">{t('Vartotojas')}</p>
            <Input onEnter={onSubmit} placeholder={t('Vartotojas')} value={name} type="text" onChangeValue={onChangeName} isValid={name.length > 5 || name.length === 0} />
          </div>
          <div>
            <p className="text-left text-sm font-semibold  mb-2">{t('Slaptažodis')}</p>
            <Input onEnter={onSubmit} value={password} onChangeVisible={onChangeVisible} onChangeValue={onChangePassword} placeholder={t('Slaptažodis')} type="password" isValid={true} isVisible={isVisible} />
          </div>
          {(!!AllowRemindMe || !!AllowPasswordRemind) &&
            <div className="flex justify-between ">
              {!!AllowRemindMe &&
                <div className="flex   ">
                  <Checkbox name="remind" className="mr-3" isChecked={isChecked} onCheckedChange={onChangeChecked} />
                  <label htmlFor="remind" className="text-sm">{t('Prisiminti')}</label>
                </div>
              }
              {!!AllowPasswordRemind &&
                <span onClick={onChangeActiveModal} className="text-sky-700 text-sm cursor-pointer font-semibold">{t('Pamiršote slaptažodį?')}</span>
              }
            </div>
          }

          {!!error && <div className='duration-200 bg-red-50 text-red-700 p-2 rounded-md'>{t(error)}</div>}

          <Button onClick={onSubmit}>{t('Prisijungti')}</Button>
          {!!AllowRegistration &&
            <div className="text-sm text-center">{t('Neturite paskyros?')} <Link to={`${process.env.REACT_APP_PUBLIC_URL}sign-up`} className="text-sky-700 font-semibold">{t('Sukurti paskyrą')}</Link></div>
          }
          <div className="text-center relative">
            <hr />
          </div>
          <div className="flex gap-2 items-center">
            {ImageBottom.map(url => <img src={url} alt="" className="mx-auto" />)}
          </div>
        </div>
      </Wrapper>
      <Modal onChangeActive={onChangeActiveModal} onChangeSended={onChangeSendedModal} onChangeValue={onChangeEmailModal} value={emailModal} isActive={isActiveModal} isSended={isSendedModal} />
    </div>

  )
}