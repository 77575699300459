import React from 'react'
import { useDataProvider } from '../../../components/DataProvider'
import { invertColor } from '../../../components/utils'
import LeftPhoto from './LeftPhoto'

type Props = {
    children: any
}

export default function Wrapper(props: Props) {
    const { BackgroundColor } = useDataProvider();
    return (
        <div
            className="flex items-center justify-items-center"
            style={{
                backgroundColor: BackgroundColor,
                color: invertColor(BackgroundColor, true)
            }}
        >
            <LeftPhoto />
            <div className="max-h-[100vh] overflow-y-auto py-10 flex-grow">
                <div className={'w-full p-4 lg:p-0 lg:pl-12 xl:pl-24'}>
                    <div className={'w-[100%]'}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}
