import React from 'react'
import { useDataProvider } from './DataProvider'
import UpdateV1 from '../AuthV1/screens/Update/Update';

import { Update as UpdateV2 } from '../AuthV2/pages/Update';

export default function Update() {
    const { Design } = useDataProvider();

    if (Design === 'v1') {
        return <UpdateV1 />
    }

    return <UpdateV2 />
}
