import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "Prisijunkite": "Sign in",
          "Vartotojas": "Username",
          "Slaptažodis": "Password",
          "Pakartokite slaptažodį": "Repeat password",

          "Prisijungti": "Sign in",
          "Prisiminti": "Remind me",
          "Pamiršote slaptažodį?": "Forgot password?",
          "Sukurti paskyrą": "Sign up",
          "Neteisingas slaptažodis arba prisijungimo vardas": "Wrong password or username",

          'Vardas': "First name",
          'Pavardė': "Last name",
          'El. paštas': 'E-mail',
          'Telefonas': "Phone",
          'Turite paskyra?': 'Already a member?',

          'Privatumo politika': "Privacy policy",
          'Sutinku su sąlygomis': 'I agree to the terms',

          'Neturite paskyros?': 'Don\'t have an account?',

          'Atstatyti slaptažodį': "Password remind",
          'Atstatyti': "Submit",
          'Patikrink savo paštą': 'Check your inbox',

          'Išsiuntėme el. laišką adresu su instrukcijomis.': 'We have sent an e-mail to {{email}} with instructions.',

          'Įveskite savo el. pašto adresą ir mes atsiųsime jums el. laišką su instrukcijom.': 'Enter your email address and we will email you letter with instructions.',

          'Keisti slaptažodį': 'Update password',

          'Prisijungimo vardas': 'Username',

          'Įveskite prisijungimo vardą': 'Enter username',
          'Įveskite slaptažodį': 'Enter password',
          'Neteisingas vartotojo vardas arba slaptažodis': 'Wrong username or password',
          'Įveskite el. pašto adresą': 'Enter e-mail',
          'Slaptažodžiai nesutampa': 'Passwords don\t match',

          'Neteisingas el. pašto adresas': 'Wrong email address',

          'Slaptažodį turi sudaryti bent 8 simboliai': 'Password should contain at least 8 characters',

          'Slaptažodis pakeistas': 'Password changed',
        }
      },
      lt: {
        translation: {
          'Išsiuntėme el. laišką adresu su instrukcijomis.': 'Išsiuntėme el. laišką adresu {{email}} su instrukcijomis.',
        }
      }
    },
    lng: "lt", // if you're using a language detector, do not define the lng option
    fallbackLng: "lt",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
