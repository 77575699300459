import React, { Fragment, useEffect, useState } from 'react'
import InputSign from '../AuthV1/components/inputSign/InputSign';
import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../components/DataProvider';
import PopupWindow from '../components/Popup/PopupWindow';
import { LoginUser, TwoFaCodeVerifySignInToken } from '../api/api';
import VerifyCodeComponent from './Verify/VerifyCodeComponent';
import VerifyFidoComponent from './Verify/VerifyFidoComponent';

export type Device = {
    secret: string,
    data: any,
    name: string,
    type: string,
}

type Props = {
    devices: Device[],

    authOptions: LoginUser,

    onSuccess: (token: string) => void,
}

export default function AuthTwoFaVerify(props: Props) {
    const { t } = useTranslation('');

    const { AccentColor } = useDataProvider();
    const [activeDevice, setActiveDevice] = useState<Device>();

    useEffect(() => {
        if (props.devices.length === 1) {
            setActiveDevice(props.devices[0]);
        }
    }, []);

    return (
        <PopupWindow>
            <div className=' space-y-[24px]'>
                <div className={'text-[28px] font-medium'}>
                    {t('Verifying 2FA')}
                </div>

                {props.devices.map((device, idx) => {
                    return (
                        <div key={`device-${idx}`} className='cursor-pointer' onClick={() => setActiveDevice(device)}>
                            {device.name}
                        </div>
                    )
                })}

                {!!activeDevice && <Fragment>
                    {activeDevice.type === 'code' && <VerifyCodeComponent
                        device={activeDevice}
                        onSuccess={props.onSuccess}
                        authOptions={props.authOptions}
                    />}
                    {activeDevice.type === 'fido' && <VerifyFidoComponent
                        device={activeDevice}
                        onSuccess={props.onSuccess}
                        authOptions={props.authOptions}
                    />}
                </Fragment>}

            </div>
        </PopupWindow>
    )
}
