import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PopupWindow from '../components/Popup/PopupWindow';
import CodeComponent from './Register/CodeComponent';
import { useDataProvider } from '../components/DataProvider';
import classNames from 'classnames';
import FidoComponent from './Register/FidoComponent';
import { LoginUser } from '../api/api';

type Props = {
    twoFaCode: {
        secret: string,
        uri: string,
    }
    twoFaFido: any,

    authOptions: LoginUser,

    onSuccess: (token: string) => void,
}

export default function AuthTwoFaRegister(props: Props) {
    const { t } = useTranslation();
    const { AccentColor } = useDataProvider();

    const [type, setType] = useState(types[0].type);

    return (
        <PopupWindow>
            <div className=' space-y-[24px] md:w-[440px] w-full'>
                <div className={'text-[28px] font-medium'}>
                    {t('Setting Up 2FA')}
                </div>

                <div className='flex gap-4' style={{ color: AccentColor }}>
                    {types.map((item) => {
                        return (
                            <button
                                key={`t-${item.type}`}
                                className={classNames(
                                    { 'underline underline-offset-4': type === item.type }
                                )}
                                onClick={() => setType(item.type)}
                            >
                                {t(item.title)}
                            </button>
                        )
                    })}

                </div>

                {type === 'code' && <CodeComponent {...props} />}
                {type === 'fido' && <FidoComponent {...props} />}
                
            </div>
        </PopupWindow>
    )
}

const types = [
    {
        type: 'code',
        title: 'Code',
    },
    {
        type: 'fido',
        title: 'Security key',
    }
]