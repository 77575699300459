import { ChangeEvent, FC, useState } from "react"
import { Button, Input, Wrapper } from "../components/index"
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { changePassword } from "../../api/api";

export const Update: FC = () => {
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);

  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  const [isVisible, SetIsVisible] = useState(false)
  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
  const onChangePasswordRepeat = (e: ChangeEvent<HTMLInputElement>) => setPasswordRepeat(e.target.value)

  const onChangeVisible = () => SetIsVisible(!isVisible)

  const onSubmit = () => {
    if (!password) {
      setError("Įveskite slaptažodį");
      return;
    }
    if (password !== passwordRepeat) {
      setError("Slaptažodžiai nesutampa");
      return;
    }

    let token = '';
    if (!!searchParams.get('token')) {
      // @ts-ignore
      token = searchParams.get('token');
    }

    changePassword({
      password,
      token,
    }).then(res => {
      if (res.data.success === 1) {
        setChanged(true);
        setError('');
      } else {
        setError('Klaida')
      }
    })
  }

  return (
    <div className="flex items-center justify-center h-screen lg:bg-slate-50">
      <Wrapper>
        <div className="mt-10 space-y-[24px]">
          <p className="text-3xl font-medium">{t('Keisti slaptažodį')}</p>
          <form className="space-y-2">
            <div>
              <p className="mb-2 text-sm font-semibold">{t('Slaptažodis')}</p>
              <Input isVisible={isVisible} onChangeVisible={onChangeVisible} onChangeValue={onChangePassword} placeholder={t('Slaptažodis')} type="password" value={password} isValid={password.length > 5 || password.length === 0} />
            </div>
            <div>
              <p className="mb-2 text-sm font-semibold">{t('Pakartokite slaptažodį')}</p>
              <Input isVisible={isVisible} onChangeVisible={onChangeVisible} onChangeValue={onChangePasswordRepeat} placeholder={t('Pakartokite slaptažodį')} type="password" value={passwordRepeat} isValid={passwordRepeat.length > 5 || passwordRepeat.length === 0} />
            </div>
          </form>

          {!!error && <div className='duration-200 bg-red-50 text-red-700 p-2 rounded-md'>{t(error)}</div>}

          {!!changed && <div className='duration-200 bg-teal-50 text-teal-700 p-2 rounded-md'>{t('Slaptažodis pakeistas')}</div>}

          <Button onClick={onSubmit}>{t('Keisti slaptažodį')}</Button>
        </div>
      </Wrapper>
    </div>
  )
}