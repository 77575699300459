import React, { Fragment, useState } from 'react'
import { LoginUser, TwoFaCodeVerifySignInToken, TwoFaFidoVerifySignInToken } from '../../api/api';
import { useDataProvider } from '../../components/DataProvider';
import { useTranslation } from 'react-i18next';
import { Device } from '../AuthTwoFaVerify';
import SimpleWebAuthnBrowser, { startAuthentication } from '@simplewebauthn/browser';

type Props = {
    device: Device,
    authOptions: LoginUser,

    onSuccess: (token: string) => void,
}

export default function VerifyFidoComponent(props: Props) {
    const { t } = useTranslation('');

    const { AccentColor } = useDataProvider();

    const [isError, setIsError] = useState(false);

    const onSubmit = async () => {
        let attResp;
        try {
            // Pass the options to the authenticator and wait for a response
            attResp = await startAuthentication(props.device.data);
        } catch (error: any) {
            console.log({ error })
            // Some basic error handling
            if (error.name === 'InvalidStateError') {
                // elemError.innerText = 'Error: Authenticator was probably already registered by user';
            } else {
                // elemError.innerText = error;
            }

            // throw error;
            setIsError(true);
        }
        

        const res = await TwoFaFidoVerifySignInToken(
            {
                secret: props.device.data.challenge,
                response: attResp,
                authOptions: props.authOptions,
            }
        )
        if (res.data.action === 'logged-in') {
            props.onSuccess(res.data.token);
        } else {
            setIsError(true);
        }
    }

    return (
        <Fragment>
            <ul className='list-disc'>
                <li>Insert your security key into an available USB port or follow the wireless pairing instructions if it's a Bluetooth-enabled device.</li>
                <li>The platform will prompt you with on-screen instructions. Typically, this involves pressing a button on your FIDO2 device or confirming the connection.</li>
                <li>Complete any additional steps required for verifying your identity.</li>
            </ul>

            {isError && <p className={'text-[#EF4444] text-[12px]'}>{t('Wrong token')}</p>}

            <button
                onClick={onSubmit}
                className={'w-[100%] py-[16px] text-[#ffffff]'}
                style={{
                    backgroundColor: AccentColor,
                }}
            >
                {t('Start')}
            </button>
        </Fragment>
    )
}
