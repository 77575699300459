import { axiosInstance } from "./config";

export type LoginUser = {
  username: string;
  password: string;
  remindMe?: boolean;
}

export type CreateUserDto = {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  password?: string;
  username?: string;
};

export const createUser = (data: CreateUserDto) => {
  return axiosInstance
    .post("sign-up", {
      data,
      origin: window.location.href,
    })
};

export const loginUser = (data: LoginUser) => {
  return axiosInstance
    .post("sign-in", {
      data,
      origin: window.location.href,
    })
};


type RemindUserDto = {
  username: string;
};

export const remindUser = (data: RemindUserDto) => {
  axiosInstance
    .post("remind", {
      data,
      origin: window.location.href,
    })
    .then(() => {});
};


type ChangepasswordDto = {
  password: string;
  token: string;
};

export const changePassword = (data: ChangepasswordDto) => {
  return axiosInstance
    .post("change-password", {
      data,
      origin: window.location.href,
    })
};



type TwoFaCodeVerifyTokenDto = {
  authOptions: LoginUser;
  secret: string;
  token: string;
};

export const TwoFaCodeVerifySignInToken = (data: TwoFaCodeVerifyTokenDto) => {
  return axiosInstance
    .post("two-fa/code/verifySignInToken", {
      data,
      origin: window.location.href,
    })
};

export const TwoFaCodeVerifySignUpToken = (data: TwoFaCodeVerifyTokenDto) => {
  return axiosInstance
    .post("two-fa/code/verifySignUpToken", {
      data,
      origin: window.location.href,
    })
};

type TwoFaFidoVerifyTokenDto = {
  authOptions: LoginUser;
  secret: string;
  response: any;
};

export const TwoFaFidoVerifySignUpToken = (data: TwoFaFidoVerifyTokenDto) => {
  return axiosInstance
    .post("two-fa/fido/verifySignUpToken", {
      data,
      origin: window.location.href,
    })
};

export const TwoFaFidoVerifySignInToken = (data: TwoFaFidoVerifyTokenDto) => {
  return axiosInstance
    .post("two-fa/fido/verifySignInToken", {
      data,
      origin: window.location.href,
    })
};