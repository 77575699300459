import React, { FC, useState } from 'react';
import InputSign from "../inputSign/InputSign";
import { BUTTON_X, CHECK } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { remindUser } from '../../../api/api';
import { useDataProvider } from '../../../components/DataProvider';

interface ModalForgotPasswordinterface {
    setForgotPassword: (forgotPassword: boolean) => void
}

const ModalForgotPassword: FC<ModalForgotPasswordinterface> = ({ setForgotPassword }) => {
    const { t } = useTranslation();

    const { BackgroundColor, AccentColor } = useDataProvider();

    const [email, setEmail] = useState('')
    const [modalCode, setModalCode] = useState(false)
    const handleEmailChange = (e: string) => {
        setEmail(e);
    }

    const onSubmit = () => {
        setModalCode(true);
        remindUser({
            username: email
        })
    }

    return (
        <>
            {
                modalCode
                    ? <div
                        className={'absolute w-[100%] h-[100%] bottom-0 left-0 right-0 top-0 bg-black/50 flex items-center justify-center'}
                        onClick={() => setForgotPassword(false)}
                    >
                        <div
                            className={'relative max-w-[535px] p-[40px] rounded-[8px]'}
                            style={{
                                backgroundColor: BackgroundColor
                            }}
                        >
                            <div className={'flex ml-[58px]'}>
                                <img src={CHECK} alt="" className={'absolute left-[50px] top-[28%]'} />
                                <p className={'text-[28px] font-medium'}>{t('Patikrink savo paštą')}</p>
                            </div>
                            <div className={'ml-[58px]'}>
                                {t('Išsiuntėme el. laišką adresu su instrukcijomis.', { email })}
                            </div>
                        </div>
                    </div>
                    : <div
                        className={'absolute w-[100%] h-[100%] bottom-0 left-0 right-0 top-0 bg-black/50 flex items-center justify-center'}
                    >
                        <div
                            className={'relative max-w-[535px] p-[40px] rounded-[8px]'}
                            style={{
                                backgroundColor: BackgroundColor
                            }}
                        >
                            <div
                                className={'absolute right-[20px] top-[44px] cursor-pointer'}
                                onClick={() => setForgotPassword(false)}>
                                <img src={BUTTON_X} alt="" />
                            </div>
                            <div className={'text-[28px] font-medium'}>
                                {t('Atstatyti slaptažodį')}
                            </div>
                            <div className={'text-[16px] my-[24px]'}>
                                {t('Įveskite savo el. pašto adresą ir mes atsiųsime jums el. laišką su instrukcijom.')}
                            </div>
                            <InputSign title={t('El. paštas')} value={email} handleChange={handleEmailChange} />
                            <button
                                className={'py-[8px] px-[16px] mt-[27px] rounded-[4px] text-[#fff]'}
                                onClick={onSubmit}
                                style={{
                                    backgroundColor: AccentColor
                                }}
                            >{t('Atstatyti')}</button>
                        </div>
                    </div>
            }
        </>
    );
}

export default ModalForgotPassword;
