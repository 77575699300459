import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import classNames from 'classnames';

interface InputSignProps {
    title: string | any
    handleChange: (e: string) => void
    isWrongCredentials?: boolean,
    type?: string,
    value: string,
    onEnter?: () => void,
}
const InputSign: FC<InputSignProps> = ({ title, value, handleChange, isWrongCredentials, type, onEnter }) => {
    const { t } = useTranslation();

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            if (onEnter) {
                onEnter();
            }
        }
    }

    return (
        <div className="w-[100%] pr-[10px]">
            <p className={'mb-[8px]'}>{title}</p>
            <input
                value={value}
                type={type ? type : 'text'}
                placeholder={title}
                className={
                    classNames(
                        'text-slate-800',
                        'border-[1px] divide-[#243c5a] py-[15px] pl-[15px] w-[100%] rounded-[12px] outline-none',
                        { 'border-[#EF4444]': isWrongCredentials }
                    )
                }
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {isWrongCredentials && <p className={'text-[#EF4444] text-[12px]'}>{t('Neteisingas slaptažodis arba prisijungimo vardas')}</p>}
        </div>
    );
}

export default InputSign;
