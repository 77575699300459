import { ChangeEvent, HTMLInputTypeAttribute, FC } from "react"
import { ReactComponent as VisiblePass } from '../assets/visiblePass.svg'
import { ReactComponent as InVisiblePass } from '../assets/inVisiblePass.svg'


type Props = {
  type: HTMLInputTypeAttribute
  placeholder: string
  isValid?: boolean
  isVisible?: boolean
  value: string
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeVisible?: () => void
  onEnter?: () => void
}

const getType = (type: HTMLInputTypeAttribute, isVisible: boolean): HTMLInputTypeAttribute => {
  if (type === 'password' && isVisible) {
    return 'text'
  }
  return type
}

export const Input: FC<Props> = ({ type, onChangeValue, placeholder, value, onChangeVisible, isVisible, onEnter }) => {
  const isValid = true;

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
        if (onEnter) {
            onEnter();
        }
    }
}

  // ${type === 'tel' ? ' rounded-r-md' : 'rounded-md'}
  return (
    <>
      <div className={isValid ? "relative mb-6" : "relative"}>
        <div className="flex">
          {/* {type === 'tel' ? <div className="p-3 text-sm bg-[#E2E8F0] rounded-l-md border-y-2 border-l-2">+370</div> : null} */}
          <input
            autoComplete={type === "password" ? 'on' : 'off'}
            className={`
           placeholder:text-sm focus:outline-none border w-full py-2  pl-4 pr-12
           
           ${isValid ? 'focus:border-sky-600' : 'focus:border-red-500'}`}
            type={getType(type, !!isVisible)}
            placeholder={placeholder}
            value={value}
            onChange={onChangeValue}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div onClick={onChangeVisible} className="cursor-pointer select-none top-1/4 right-4 absolute">
          {type === 'password' && value.length > 0 &&
            <> {isVisible ? <VisiblePass /> : <InVisiblePass />}</>
          }
        </div>
      </div>
      {!isValid && <div className="text-left text-red-500">{type === 'text' ? "Nurodytas neteisingas prisijungimas" : 'Nurodytas neteisingas slaptažodis'}</div>}
    </>

  )
}