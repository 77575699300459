import React, { ReactNode } from 'react'
import { useDataProvider } from '../DataProvider';

type Props = {
    children: ReactNode
}

export default function PopupWindow(props: Props) {
    const { BackgroundColor } = useDataProvider();

    return (
        <div
            className={'absolute w-[100%] h-[100%] bottom-0 left-0 right-0 top-0 bg-black/50 flex items-center justify-center'}
        >
            <div
                className={'relative max-w-[535px] p-[40px] rounded-[8px] max-h-[100vh] overflow-y-auto'}
                style={{
                    backgroundColor: BackgroundColor
                }}
            >
                {props.children}
            </div>
        </div>
    )
}
