import { FC } from "react"

type Props = {
  className?: string
  isChecked: boolean
  onCheckedChange: (val: boolean) => void,
  name?: string
}
export const Checkbox: FC<Props> = ({ isChecked, onCheckedChange, className, name }) => {
  return (
    <input id={name} name={name} checked={isChecked} onChange={() => onCheckedChange(!isChecked)} className={`w-[21px] focus:outline-none h-[21px] ${className}`} type="checkbox" />
  )
}