import React from 'react'
import { useDataProvider } from './DataProvider'
import SignUpV1 from '../AuthV1/screens/SignUp/SignUp';
import { SignUp as SignUpV2 } from '../AuthV2/pages/SignUp';

export default function SignUp() {
    const { Design } = useDataProvider();

    if (Design === 'v1') {
        return <SignUpV1 />
    }

    return <SignUpV2 />
}
